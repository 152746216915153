<template>
    <div>
        <el-breadcrumb separator="/">
            <el-breadcrumb-item
                :to="{
                    path: '/academic_Management/academic_font_management/academic_font_management_home',
                }"
                >学术系统</el-breadcrumb-item
            >
            <el-breadcrumb-item>复议单词</el-breadcrumb-item>
        </el-breadcrumb>
        <el-page-header @back="goBack"></el-page-header>
        <!-- 筛选 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span class="title_class">条件筛选</span>
                </el-col>
            </el-row>
            <el-form ref="queryFormRef" :model="queryForm" label-width="80px">
                <el-row :gutter="50">
                    <el-col :span="6">
                        <el-form-item label="赛季" prop="season">
                            <el-select
                                placeholder="请选择类型"
                                style="width: 100%"
                                v-model="queryForm.season"
                            >
                                <el-option
                                    v-for="item in dict_season"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select> </el-form-item
                    ></el-col>
                    <el-col :span="6">
                        <el-form-item label="赛程" prop="raceSchedule">
                            <el-select
                                v-model="queryForm.raceSchedule"
                                placeholder="请选择"
                                style="width: 100%"
                            >
                                <el-option
                                    v-for="item in dict_matchType"
                                    :key="item.id"
                                    :label="item.scheduleName"
                                    :value="item.id"
                                ></el-option>
                            </el-select> </el-form-item
                    ></el-col>
                    <el-col :span="6">
                        <el-form-item label="所属赛区" prop="districtId">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.districtId"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_district"
                                    :key="item.id"
                                    :label="item.districtName"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="组别" prop="level">
                            <el-select
                                placeholder="请选择"
                                v-model="queryForm.level"
                                style="width: 100%"
                            >
                                <el-option
                                    v-for="item in dict_Group"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="6">
                        <el-form-item label="名称" prop="name">
                            <el-input
                                v-model="queryForm.name"
                                placeholder="请输入"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col> -->
                </el-row>
            </el-form>
            <el-row :gutter="20" type="flex" justify="end">
                <el-col :span="2">
                    <el-button
                        type="primary"
                        style="width: 100%"
                        @click="searchResult"
                        >查询</el-button
                    >
                </el-col>
                <el-col :span="2">
                    <el-button
                        type="primary"
                        plain
                        style="width: 100%"
                        @click="reset"
                        >重置</el-button
                    >
                </el-col>
            </el-row>
        </el-card>
        <!-- 列表 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row" align="middle" type="flex">
                <el-col :span="5">
                    <span class="title-class title_class">单词列表</span>
                </el-col>
            </el-row>
            <el-table
                header-cell-class-name="tableHeaderStyle"
                :data="dataList"
                border
                stripe
            >
                <el-table-column label="序号" type="index"></el-table-column>
                <el-table-column label="赛季" prop="season"> </el-table-column>
                <el-table-column
                    label="赛程"
                    prop="raceScheduleName"
                ></el-table-column>
                <el-table-column
                    label="赛区"
                    prop="districtName"
                ></el-table-column>
                <el-table-column
                    label="组别"
                    prop="level"
                    :formatter="groupFormatter"
                ></el-table-column>
                <el-table-column
                    label="选手作答"
                    prop="userAnswer"
                    width="280px"
                    :show-overflow-tooltip="true"
                >
                    <template slot-scope="scope">
                        <div
                            style="color: red;"
                            v-for="item in scope.row.userAnswerList"
                            v-bind:key="item"
                        >
                            {{ item }}</div
                        >
                    </template>
                </el-table-column>
                <el-table-column label="单词" prop="spell">
                    <template slot-scope="scope">
                        <el-link
                            style="color: #409eff"
                            @click="handleToWordVoice(scope.row.purl)"
                            >{{ scope.row.spell }}</el-link
                        >
                    </template>
                </el-table-column>
                <el-table-column label="词性" prop="partSpeech"
                    ><template slot-scope="scope">
                        <el-link
                            style="color: #409eff"
                            @click="handleToWordVoice(scope.row.partUrl)"
                            >{{ scope.row.partSpeech }}</el-link
                        >
                    </template></el-table-column
                >
                <el-table-column
                    label="定义"
                    prop="def"
                    :show-overflow-tooltip="true"
                >
                    <template slot-scope="scope">
                        <el-link
                            style="color: #409eff"
                            @click="handleToWordVoice(scope.row.durl)"
                            >{{ scope.row.def }}</el-link
                        >
                    </template></el-table-column
                >
                <el-table-column
                    label="例句"
                    prop="exp"
                    :show-overflow-tooltip="true"
                >
                    <template slot-scope="scope">
                        <el-link
                            style="color: #409eff"
                            @click="handleToWordVoice(scope.row.eurl)"
                            >{{ scope.row.exp }}</el-link
                        >
                    </template></el-table-column
                >
                <el-table-column label="音标" prop="phonetic">
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryForm.pageNum"
                :page-sizes="[10, 20, 40, 80]"
                :page-size="queryForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </el-card>
        <audio ref="audioPlayer" @ended="overAudio"></audio>
    </div>
</template>

<script>
import { markPlayerQuestionErrorList, queryDistrictPage } from '@/http/api'
export default {
  components: {
  },
  data () {
    return {
      isEdit: false,
      dataList: [],
      dict_district: [],
      raceScheduleList: this.$chnEngPublicRequest.querySeasonRaceSchedule(this, { activityType: '1' }).then(value => { this.raceScheduleList = value }),
      dict_matchType: this.$chnEngPublicRequest.querySeasonRaceSchedule(this, { activityType: '1' }).then(value => { this.dict_matchType = value }),
      dict_season: this.$userInfo.getDataList(this, 't_season').then(value => { this.dict_season = value }),
      dict_Group: this.$userInfo.dict_group(),
      questionPaper_type: this.$userInfo.questionPaper_type(),
      total: 0,
      queryForm: {
        season: this.$chnEngStatusCode.defaultSeason,
        activityType: this.$chnEngStatusCode.activityType,
        raceSchedule: '',
        districtId: '',
        name: '',
        level: null,
        pageNum: 1,
        pageSize: 10
      }
    }
  },
  created () {
    this.searchResult()
    this.getDistrictList()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    searchResult () {
      markPlayerQuestionErrorList(this.queryForm).then((res) => {
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 单词点击事件
    handleToWordVoice (voiceUrl) {
      this.$refs.audioPlayer.src = voiceUrl
      this.$refs.audioPlayer.play()
    },
    // 获取赛区筛选数据
    getDistrictList () {
      queryDistrictPage({ pageNum: 1, pageSize: 10000 }).then((res) => {
        this.dict_district = res.data.list
      }).catch((err) => {
        console.log('err', err)
      })
    },
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.queryForm.pageNum = 1
      this.queryForm.pageSize = 10
      this.searchResult()
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.searchResult()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.searchResult()
    },
    overAudio () {
    },
    // 跳转详情
    turnDetailData (rowData) {
      this.$router.push({
        path: '/academic_Management/academic_font_management/academic_examination_fengtalk',
        query: {
          paperId: rowData.id,
          level: rowData.level
        }
      })
    },
    groupFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_Group) {
        if (Object.hasOwnProperty.call(this.dict_Group, key)) {
          const element = this.dict_Group[key]
          if (element.dictValue === data.level) {
            temp = element.dictLabel
          }
        }
      }
      return temp
    }
  }
}
</script>

<style>
</style>
